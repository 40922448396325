import { useContext, useEffect, useMemo, useState } from 'react';

import { Stack, Typography } from '@mui/material';

import Card from '@/components/card';
import Carousel from '@/components/carousel/Carousel';
import VideoPlayer from '@/components/video';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import { Posts } from '@/models/posts';

import PostIdentificationMarkers from './PostIdentificationMarkers';

const PostMedia = () => {
  const {
    data,
    videoPlaybackStart,
    videoLastClick,
    activeIndex,
    showIdentificationMarkers,
    darkenImageStyle,
    setActiveIndex,
    setIdentificationMarkers,
    setVideoPlayedSeconds,
    selectedMinorItems,
    setSelectedMinorItems,
    selectedOcrAdvItems,
    setSelectedOcrAdvItems
  } = useContext(PostDetailsContext);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isPlaying, setIsPlaying] = useState(false);

  // VIDEO
  useEffect(() => {
    if (videoPlaybackStart !== undefined && showIdentificationMarkers)
      setIsPlaying(false);
  }, [videoPlaybackStart]);

  const handleOnLoadImage = () => {
    if (data === undefined) return;

    const element = document.getElementById(data.media[activeIndex].mediaPath);
    if (element) {
      const { clientWidth: width, clientHeight: height } = element;
      if (width || height) setDimensions({ width, height });
    }
  };

  const handleOnProgressVideo = (playedSeconds: number) => {
    if (playedSeconds > 0) {
      setVideoPlayedSeconds(playedSeconds);
    }
  };

  const handleOnPlay = () => {
    if (showIdentificationMarkers) setIdentificationMarkers(false);
    setIsPlaying(true);
  };

  const resetMarkers = () => {
    setSelectedMinorItems([]);
    setSelectedOcrAdvItems([]);
  };

  const markerItems: Posts.MarkerItem[] = useMemo(
    () => [...selectedMinorItems, ...selectedOcrAdvItems],
    [selectedMinorItems, selectedOcrAdvItems]
  );

  // CAROUSEL
  const carouselItems = useMemo(
    () =>
      data?.media.map((item, idx) =>
        item.mediaType === 'video' ? (
          <VideoWithMarkers
            key={`${item.mediaPath}${data.title}${idx}`}
            item={data.media[activeIndex]}
            showIdentificationMarkers={showIdentificationMarkers}
            onLoad={handleOnLoadImage}
            onProgress={handleOnProgressVideo}
            onPlay={handleOnPlay}
            markers={{
              items: markerItems,
              dimensions
            }}
            platform={data.platform}
            videoPlaybackStart={videoPlaybackStart}
            videoLastClick={videoLastClick}
            playing={isPlaying}
          />
        ) : (
          <ImageWithMarkers
            key={`${item.mediaPath}${data.title}${idx}`}
            item={data.media[activeIndex]}
            showIdentificationMarkers={showIdentificationMarkers}
            onLoad={handleOnLoadImage}
            markers={{
              items: markerItems,
              dimensions
            }}
            title={data.title}
            filter={darkenImageStyle}
          />
        )
      ),
    [
      activeIndex,
      videoLastClick,
      dimensions,
      showIdentificationMarkers,
      darkenImageStyle,
      selectedMinorItems,
      selectedOcrAdvItems
    ]
  );

  useEffect(() => {
    const handleResize = () => {
      handleOnLoadImage(); // Ensure dimensions and offsets are recalculated
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!data) return <Typography variant="body2">Data not available</Typography>;

  return (
    <>
      <Card
        shadow
        sidePadding={false}
        style={{
          padding: 0,
          height: 'calc(100% - 72px)'
        }}
        content={
          <>
            {data?.media.length > 1 && (
              <Carousel
                cycleNavigation={false}
                autoPlay={false}
                activeIndex={activeIndex}
                onChangeVisibleItem={setActiveIndex}
                resetMarkers={resetMarkers}
                onChange={(now) => setActiveIndex(now || 0)}
                items={carouselItems || []}
                id={data?.id}
                sx={{
                  height: 'calc(100vh - 252px)' // Need to adjust size due to carousel controls
                }}
              />
            )}
            {data?.media.length === 1 &&
              data.media[activeIndex]?.mediaType === 'image' && (
                <ImageWithMarkers
                  key={`${data.media[activeIndex]?.mediaPath}${data.title}`}
                  item={data.media[activeIndex]}
                  showIdentificationMarkers={showIdentificationMarkers}
                  onLoad={handleOnLoadImage}
                  markers={{
                    items: markerItems,
                    dimensions
                  }}
                  title={data.title}
                  filter={darkenImageStyle}
                />
              )}
            {data?.media.length === 1 &&
              data.media[activeIndex]?.mediaType === 'video' && (
                <VideoWithMarkers
                  key={`${data.media[activeIndex]?.mediaPath}${data.title}`}
                  item={data.media[activeIndex]}
                  showIdentificationMarkers={showIdentificationMarkers}
                  onLoad={handleOnLoadImage}
                  onProgress={handleOnProgressVideo}
                  onPlay={handleOnPlay}
                  markers={{
                    items: markerItems,
                    dimensions
                  }}
                  platform={data.platform}
                  videoPlaybackStart={videoPlaybackStart}
                  videoLastClick={videoLastClick}
                  playing={isPlaying}
                />
              )}
          </>
        }
      />
    </>
  );
};

interface ImageWithMarkersProps {
  item: Posts.Media;
  showIdentificationMarkers: boolean;
  onLoad: () => void;
  markers: {
    items: Posts.MarkerItem[];
    dimensions: { width: number; height: number };
  };
  title: string;
  filter: string;
  key: string;
}

const ImageWithMarkers = ({
  item,
  showIdentificationMarkers,
  onLoad,
  markers,
  title,
  filter
}: ImageWithMarkersProps) => (
  <Stack alignItems="center" display="block">
    <Stack style={{ position: 'relative', justifyContent: 'center' }}>
      <div
        id="image-container"
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          height: 'calc(100vh - 213px)'
        }}
      >
        <img
          id={item.mediaPath}
          src={item.mediaPath}
          alt={title}
          style={{
            filter,
            // maxHeight: 'calc(100vh - 213px)', // Ensures it fits within the container
            // maxWidth: '100%', // Ensures it doesn’t exceed container width
            objectFit: 'contain' // Maintains aspect ratio without stretching
          }}
          onLoad={onLoad}
        />
      </div>
      {showIdentificationMarkers && (
        <PostIdentificationMarkers
          items={markers.items}
          dimensions={markers.dimensions} // Use the actual dimensions
        />
      )}
    </Stack>
  </Stack>
);
interface VideoWithMarkersProps {
  item: Posts.Media;
  showIdentificationMarkers: boolean;
  onLoad: () => void;
  markers: {
    items: Posts.MarkerItem[];
    dimensions: { width: number; height: number };
  };
  platform: string;
  videoPlaybackStart?: number;
  videoLastClick: string | number;
  onProgress: (playedSeconds: number) => void;
  onPlay: () => void;
  playing: boolean;
}

const VideoWithMarkers = ({
  item,
  onLoad,
  markers,
  platform,
  videoPlaybackStart,
  videoLastClick,
  showIdentificationMarkers,
  onProgress,
  onPlay,
  playing
}: VideoWithMarkersProps) => (
  <Stack
    alignItems="center"
    display={platform !== 'youtube-video' ? 'flex' : 'block'}
  >
    <Stack style={{ position: 'relative' }}>
      <>
        <VideoPlayer
          id={item.mediaPath}
          url={item.mediaPath}
          seek={videoPlaybackStart}
          key={item.mediaPath}
          lastClick={videoLastClick}
          platform={platform}
          onReady={onLoad}
          onPlay={onPlay}
          onProgress={(data) => onProgress(data.playedSeconds)}
          playing={playing}
        />
        {showIdentificationMarkers && (
          <PostIdentificationMarkers
            items={markers.items}
            dimensions={markers.dimensions}
          />
        )}
      </>
    </Stack>
  </Stack>
);

export default PostMedia;
