import { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import {
  DangerousOutlined,
  FilterAltOutlined,
  HelpOutline,
  PaidOutlined
} from '@mui/icons-material';
import {
  LinearProgress,
  Stack,
  Typography,
  Skeleton,
  Grid,
  Badge
} from '@mui/material';
import {
  AxisConfig,
  BarChart,
  ChartsYAxisProps,
  PieChart
} from '@mui/x-charts';

import Page from '@/components/Page';
import Button from '@/components/button';
import DashboardCard from '@/components/dashboardCard';
import Header from '@/components/header';
import TreeIcon from '@/components/icons/TreeIcon';
import DotIcon from '@/components/icons/compliance/DotIcon';
import {
  useFetchEvaluation,
  useFetchEvaluationStatistics
} from '@/hooks/queries/evaluations';
import { getPathWithLocalParams, useUrlParams } from '@/hooks/urlParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Evaluation } from '@/models/evaluations';
import getRoute from '@/utils/getRoute';

import ChartCard from './_partials/ChartCard';
import ExpandableLegend from './_partials/ExpandableLegend';
import FilterDrawer from './_partials/FilterDrawer';
import PieCenterLabel from './_partials/PieCenterLabel';

// Helper function to safely get values or return a default
const safeValue = (value: number | undefined, fallback = 0) =>
  value ?? fallback;

// Memoize these values to avoid recreating them on every render
const useChartData = (
  evaluationId: number,
  evaluationStatistics?: Evaluation.Statistics,
  keywordSlice?: number
) =>
  useMemo(() => {
    const { youtube, instagram, tiktok } = evaluationStatistics?.platform || {};
    const types = [
      {
        label: 'Videos',
        value: safeValue(youtube?.video),
        color: '#E74A2E',
        key: 'Youtube'
      },
      {
        label: 'Shorts',
        value: safeValue(youtube?.short),
        color: '#921B1C',
        key: 'Youtube'
      },
      {
        label: 'Stories',
        value: safeValue(instagram?.story),
        color: '#B26600',
        key: 'Instagram'
      },
      {
        label: 'Reels',
        value: safeValue(instagram?.reel),
        color: '#AE8F67',
        key: 'Instagram'
      },
      {
        label: 'Posts',
        value: safeValue(instagram?.post),
        color: '#FF9200',
        key: 'Instagram'
      },
      {
        label: 'Videos',
        value: safeValue(tiktok?.video),
        color: '#326CCB',
        key: 'Tik Tok'
      }
    ];

    const platforms = [
      {
        label: 'Youtube',
        value: safeValue(youtube?.total),
        color: '#EA5455'
      },
      {
        label: 'Instagram',
        value: safeValue(instagram?.total),
        color: '#FFB44F'
      },
      {
        label: 'Tik Tok',
        value: safeValue(tiktok?.total),
        color: '#326CCB'
      }
    ];

    const reviewedPosts = [
      {
        label: 'Reviewed',
        value: safeValue(evaluationStatistics?.rated),
        color: '#000000'
      },
      {
        label: 'Not Reviewed',
        value:
          safeValue(evaluationStatistics?.total) -
          safeValue(evaluationStatistics?.rated),
        color: '#E9E9E9'
      }
    ];

    const keywordsLength = Object.keys(
      evaluationStatistics?.keywords || {}
    ).length;

    const allKeywords = Object.keys(evaluationStatistics?.keywords || {})
      .map((keyword) => ({
        value: safeValue(evaluationStatistics?.keywords[keyword]),
        keyword
      }))
      .sort((a, b) => b.value - a.value);

    const keywordsToShow = allKeywords.slice(0, keywordSlice);

    const disclosuredPercentage =
      parseFloat(
        (
          (safeValue(evaluationStatistics?.disclosured) * 100) /
          safeValue(evaluationStatistics?.total, 1)
        ).toFixed(2)
      ) || 0;

    return {
      platforms,
      types,
      reviewedPosts,
      allKeywords,
      keywordsToShow,
      keywordsLength,
      disclosuredPercentage
    };
  }, [evaluationStatistics, keywordSlice]);

// Create reusable function for generating dashboard items
const useDashboardCardItems = (evaluationStatistics?: Evaluation.Statistics) =>
  useMemo(() => {
    const { adType, rated } = evaluationStatistics || {};
    const generatePercentage = (value: number | undefined) =>
      value
        ? parseFloat(
            ((safeValue(value) * 100) / safeValue(rated, 1)).toFixed(1)
          )
        : 0;

    return [
      {
        icon: (
          <DangerousOutlined sx={{ color: '#fff', width: 36, height: 36 }} />
        ),
        title: 'Not Relevant',
        value: safeValue(adType?.notRelevant),
        description: 'rated posts are Not Relevant',
        descriptionPercentage: generatePercentage(adType?.notRelevant)
      },
      {
        icon: <TreeIcon sx={{ color: '#fff', width: 36, height: 36 }} />,
        title: 'Organic Posts',
        value: safeValue(adType?.organic),
        description: 'rated posts are Organic',
        descriptionPercentage: generatePercentage(adType?.organic)
      },
      {
        icon: <HelpOutline sx={{ color: '#fff', width: 36, height: 36 }} />,
        title: 'Questionable Posts',
        value:
          safeValue(adType?.questionable?.compliant) +
          safeValue(adType?.questionable?.questionable) +
          safeValue(adType?.questionable?.violation),
        description: 'rated posts are Questionable',
        descriptionPercentage: generatePercentage(
          safeValue(adType?.questionable?.compliant) +
            safeValue(adType?.questionable?.questionable) +
            safeValue(adType?.questionable?.violation)
        ),
        items: [
          {
            icon: <DotIcon fontSize="inherit" color="error" />,
            text: 'Violation',
            value: adType?.questionable?.violation
          },
          {
            icon: <DotIcon fontSize="inherit" color="warning" />,
            text: 'Questionable',
            value: adType?.questionable?.questionable
          },
          {
            icon: <DotIcon fontSize="inherit" color="success" />,
            text: 'Compliant',
            value: adType?.questionable?.compliant
          }
        ]
      },
      {
        icon: <PaidOutlined sx={{ color: '#fff', width: 36, height: 36 }} />,
        title: 'Advertisement Posts',
        value:
          safeValue(adType?.advertisement?.compliant) +
          safeValue(adType?.advertisement?.questionable) +
          safeValue(adType?.advertisement?.violation),
        description: 'rated posts are Advertisement',
        descriptionPercentage: generatePercentage(
          safeValue(adType?.advertisement?.compliant) +
            safeValue(adType?.advertisement?.questionable) +
            safeValue(adType?.advertisement?.violation)
        ),
        items: [
          {
            icon: <DotIcon fontSize="inherit" color="error" />,
            text: 'Violation',
            value: adType?.advertisement?.violation
          },
          {
            icon: <DotIcon fontSize="inherit" color="warning" />,
            text: 'Questionable',
            value: adType?.advertisement?.questionable
          },
          {
            icon: <DotIcon fontSize="inherit" color="success" />,
            text: 'Compliant',
            value: adType?.advertisement?.compliant
          }
        ]
      }
    ];
  }, [evaluationStatistics]);

const defaultFilterParams = {
  adType: undefined,
  compliance: undefined,
  platform: undefined,
  createTime: undefined, // Start and end date in 'YYYY-MM-DD,YYYY-MM-DD' format
  keywords: undefined
};

const EvaluationStatistics = () => {
  const navigate = useWorkspaceNavigate();
  const evaluationId = Number(useParams().evaluationId);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [keywordSlice, setKeywordSlice] = useState<number | undefined>(7);
  const { search } = useLocation();

  const urlParams = useUrlParams(
    getRoute.evaluations.STATISTICS(evaluationId),
    defaultFilterParams
  );

  const { data: evaluation, isLoading: isEvaluationLoading } =
    useFetchEvaluation({
      id: evaluationId
    });

  const { data: evaluationStatistics, isLoading: isStatisticsLoading } =
    useFetchEvaluationStatistics({
      id: evaluationId,
      params: { ...urlParams.params }
    });

  const {
    platforms,
    types,
    reviewedPosts,
    allKeywords,
    keywordsToShow,
    keywordsLength,
    disclosuredPercentage
  } = useChartData(evaluationId, evaluationStatistics, keywordSlice);
  const dashboardCardItems = useDashboardCardItems(evaluationStatistics);

  const keywordsHeight = useMemo(
    () => Math.max((keywordSlice || keywordsToShow.length) * 40, 450),
    [keywordsToShow, keywordSlice]
  );

  // Calculate dynamic left margin based on longest keyword
  const keywordsMarginLeft = useMemo(() => {
    if (!keywordsToShow.length) return 100; // default margin

    // Find the longest keyword length
    const longestKeywordLength = Math.max(
      ...keywordsToShow.map((item) => item.keyword.length)
    );

    // Adjust the margin based on the length of the longest keyword
    const baseMargin = 100; // minimum margin
    const marginIncrementPerCharacter = 5; // adjust as needed

    return baseMargin + longestKeywordLength * marginIncrementPerCharacter;
  }, [keywordsToShow]);

  return (
    <Page
      backgroundSvgUrl={`${process.env.REACT_APP_S3_ASSETS}/misc/background.svg`}
      title="Evaluations"
      header={
        <Header
          breadcrumbsList={[
            {
              name: 'Evaluations',
              onClick: () =>
                navigate(getPathWithLocalParams(getRoute.evaluations.LIST()))
            },
            {
              name: evaluation?.title || (
                <Skeleton variant="rounded" width={100} />
              ),
              onClick: () =>
                navigate(
                  getPathWithLocalParams(
                    getRoute.evaluations.DETAIL(evaluationId)
                  )
                )
            }
          ]}
          title="Statistics"
          actions={
            <Badge
              color="secondary"
              badgeContent={search !== '' ? '' : undefined}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <Button
                loading={isStatisticsLoading}
                onClick={() => setDrawerOpen(true)}
                text="filter"
                startIcon={<FilterAltOutlined />}
              />
            </Badge>
          }
        />
      }
    >
      <Stack gap={3}>
        {isEvaluationLoading || isStatisticsLoading ? (
          <>
            <Stack direction="row" gap={3}>
              <Skeleton variant="rectangular" height={150} width="100%" />
              <Skeleton variant="rectangular" height={150} width="100%" />
              <Skeleton variant="rectangular" height={150} width="100%" />
              <Skeleton variant="rectangular" height={150} width="100%" />
            </Stack>
            <Stack direction="row" gap={3}>
              <Skeleton variant="rectangular" height={600} width="100%" />
              <Skeleton variant="rectangular" height={600} width="100%" />
            </Stack>
            <Skeleton variant="rectangular" height={580} width="100%" />
          </>
        ) : (
          <>
            {/* Dashboard Cards */}
            <Stack
              direction="row"
              flexWrap="wrap"
              mt={1.5}
              gap={3}
              justifyContent="space-between"
            >
              {dashboardCardItems.map((item, index) => (
                <DashboardCard key={index} {...item} />
              ))}
            </Stack>

            {/* Charts Row */}
            <Grid container item xs={12} spacing={3}>
              {/* Posts per Platform */}
              <Grid item xs={12} md={6}>
                <ChartCard
                  title="Posts per platform"
                  disabled={!evaluationStatistics?.total}
                  chart={
                    <PieChart
                      series={[
                        {
                          data: evaluationStatistics?.total ? platforms : [],
                          id: 'platforms',
                          highlightScope: { fade: 'global', highlight: 'item' },
                          innerRadius: 0,
                          outerRadius: 80
                        },
                        {
                          data: evaluationStatistics?.total ? types : [],
                          id: 'types',
                          highlightScope: { fade: 'global', highlight: 'item' },
                          innerRadius: 100,
                          outerRadius: 120
                        }
                      ]}
                      margin={{ right: 5 }}
                      slotProps={{ legend: { hidden: true } }}
                      width={400}
                      height={300}
                    />
                  }
                  legend={
                    evaluationStatistics?.total ? (
                      <ExpandableLegend platforms={platforms} types={types} />
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid>

              {/* Rated Posts */}
              <Grid item xs={12} md={6}>
                <Stack gap={3} flex={1}>
                  <ChartCard
                    disabled={!evaluationStatistics?.total}
                    title="Rated posts"
                    chart={
                      <PieChart
                        series={[
                          {
                            data: evaluationStatistics?.total
                              ? reviewedPosts
                              : [],
                            innerRadius: 65,
                            outerRadius: 100,
                            startAngle: -105,
                            endAngle: 105,
                            highlightScope: {
                              fade: 'global',
                              highlight: 'item'
                            }
                          }
                        ]}
                        margin={{ right: 5 }}
                        slotProps={{ legend: { hidden: true } }}
                        height={300}
                        width={400}
                      >
                        {evaluationStatistics?.total && (
                          <PieCenterLabel>
                            <Typography
                              fontSize="18px"
                              lineHeight="22px"
                              fontWeight={600}
                              component="text"
                            >
                              {`${
                                reviewedPosts[0].value
                              }/${reviewedPosts.reduce(
                                (total, post) => total + post.value,
                                0
                              )}`}
                            </Typography>
                          </PieCenterLabel>
                        )}
                      </PieChart>
                    }
                  />
                  <ChartCard
                    disabled={!evaluationStatistics?.total}
                    title="Sponsored posts"
                    showDivider={false}
                    legend={
                      <Stack width="100%">
                        <Stack direction="row" justifyContent="space-between">
                          <Typography fontWeight={500}>Posts</Typography>
                          <Typography fontWeight={500}>Sponsored</Typography>
                        </Stack>
                        <Stack>
                          <Stack
                            pt="9.5px"
                            pb="9.5px"
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography>All platform</Typography>
                            <Typography>{`${disclosuredPercentage}%`}</Typography>
                          </Stack>
                          <LinearProgress
                            variant="determinate"
                            value={disclosuredPercentage}
                          />
                        </Stack>
                      </Stack>
                    }
                  />
                </Stack>
              </Grid>
            </Grid>

            {keywordsLength > 0 && (
              <Grid item xs={12}>
                <ChartCard
                  disabled={!keywordsLength}
                  actions={
                    keywordsLength <= 7 ? (
                      <></>
                    ) : (
                      <Button
                        variant={keywordSlice ? 'contained' : 'outlined'}
                        text={keywordSlice ? 'Show all' : 'Show less'}
                        onClick={() =>
                          keywordSlice
                            ? setKeywordSlice(undefined)
                            : setKeywordSlice(7)
                        }
                      />
                    )
                  }
                  title="Occurrences per keywords"
                  chart={
                    <BarChart
                      sx={{
                        border:
                          '1px solid var(--Border-Medium, rgba(28, 28, 28, 0.14))'
                      }}
                      dataset={keywordsToShow}
                      colors={['#000']}
                      yAxis={[
                        {
                          scaleType: 'band',
                          dataKey: 'keyword',
                          categoryGapRatio: 0.52
                        } as AxisConfig<'band', unknown, ChartsYAxisProps>
                      ]}
                      series={[{ dataKey: 'value' }]}
                      layout="horizontal"
                      grid={{ vertical: true }}
                      margin={{ left: keywordsMarginLeft }}
                      height={keywordsHeight}
                    />
                  }
                />
              </Grid>
            )}
          </>
        )}
        {isDrawerOpen && (
          <FilterDrawer
            keywordOptions={allKeywords}
            defaultFiltersParams={defaultFilterParams}
            urlParams={urlParams}
            setDrawerOpen={setDrawerOpen}
            isDrawerOpen={isDrawerOpen}
          />
        )}
      </Stack>
    </Page>
  );
};

export default EvaluationStatistics;
