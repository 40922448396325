import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { Tabs, Tab, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Card from '@/components/card';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import { processMediaWithKeywords } from '@/utils/ocr';
import { processOldOcrText } from '@/utils/ocr/ocr';

import PostChildren from './PostChildren';
import PostOcr from './PostOcr';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box paddingY={2}>{children}</Box>}
    </div>
  );
};

const getTabStyles = (theme: any, selectedValue: number, tabValue: number) => ({
  textTransform: 'none',
  fontWeight: selectedValue === tabValue ? 'bold' : 'normal',
  minWidth: '120px',
  '&:hover': {
    color: 'inherit',
    backgroundColor: theme.palette.grey[200]
  },
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[100]
  }
});

const PostVisuals = () => {
  const theme = useTheme();
  const {
    data,
    keywordList,
    activeIndex,
    selectedKeywords,
    hasMinorsResults,
    isRecognitionHighlighted0to6,
    isRecognitionHighlighted7to14,
    isRecognitionHighlighted15to17,
    hasOcrAdvancedItems,
    hasOcrOldItems
  } = useContext(PostDetailsContext);

  const [value, setValue] = useState(() => {
    if (hasOcrAdvancedItems) return 1;
    if (!hasMinorsResults && hasOcrOldItems) return 1;
    return 0;
  });

  useEffect(() => {
    if (hasOcrAdvancedItems) {
      setValue(1);
    } else if (!hasMinorsResults && hasOcrOldItems) {
      setValue(1);
    } else if (hasMinorsResults && !hasOcrAdvancedItems && !hasOcrOldItems) {
      setValue(0);
    }
  }, [hasMinorsResults, hasOcrOldItems, hasOcrAdvancedItems]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const modMedia = useMemo(
    () => processMediaWithKeywords(data?.media, selectedKeywords, keywordList),
    [data?.media, selectedKeywords, keywordList]
  );

  const isAdvancedOcrHighlighted = useMemo(
    () =>
      modMedia?.some((media) =>
        media.visualRecognition?.some(
          (recognition) =>
            recognition.type === 'ocr-adv' &&
            recognition.items.some(
              (item) =>
                ('textFound' in item &&
                  item.textFound.some((text) =>
                    text.content.includes('</b>')
                  )) ||
                ('content' in item && item.content.includes('</b>'))
            )
        )
      ),
    [modMedia]
  );

  const highlightedOldOcr = useMemo(
    () => processOldOcrText(data?.media[activeIndex], selectedKeywords),
    [data, selectedKeywords, activeIndex]
  );

  const isOldOcrHighlighted = useMemo(
    () => highlightedOldOcr?.includes('</b>'),
    [highlightedOldOcr]
  );

  const hasBorder =
    isRecognitionHighlighted0to6 ||
    isRecognitionHighlighted7to14 ||
    isRecognitionHighlighted15to17 ||
    isOldOcrHighlighted ||
    isAdvancedOcrHighlighted;

  const hasOcrResults = hasOcrAdvancedItems || hasOcrOldItems;

  if (!hasMinorsResults && !hasOcrResults) return <></>;

  return (
    <Card
      sx={{ marginBottom: 2 }}
      hasBorder={hasBorder}
      isCollapsible
      title="Visuals"
      content={
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              '& .MuiTabs-indicator': {
                height: '4px'
              }
            }}
          >
            {hasMinorsResults && (
              <Tab
                label="Children"
                value={0}
                sx={getTabStyles(theme, value, 0)}
              />
            )}
            {hasOcrResults && (
              <Tab
                label="Text on screen"
                value={1}
                sx={getTabStyles(theme, value, 1)}
              />
            )}
          </Tabs>
          <TabPanel value={value} index={0}>
            <PostChildren />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PostOcr />
          </TabPanel>
        </>
      }
    />
  );
};

export default PostVisuals;
