import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { NavLink as RouterLink, useParams } from 'react-router-dom';

import { FileCopy, PieChart } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import { Error as ErrorIcon } from '@mui/icons-material/Error';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import {
  Box,
  CardActionArea,
  Grid,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { makeStyles } from 'tss-react/mui';

import { getEvaluation } from '@/api/evaluations';
import Page from '@/components/Page';
import Button from '@/components/button';
import Card from '@/components/card';
import DataTable from '@/components/dataTable';
import NoRowsOverlay from '@/components/dataTable/customNoRowsOverlay/NoRowsOverlay';
import OnBoardingOverlay from '@/components/dataTable/customNoRowsOverlay/OnBoardingOverlay';
import Dialog from '@/components/dialog';
import Header from '@/components/header';
import Toolbar from '@/components/toolbar';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import {
  useDeleteEvaluation,
  useFetchEvaluations
} from '@/hooks/queries/evaluations';
import { useAppSelector } from '@/hooks/redux';
import { useUrlParams } from '@/hooks/urlParams';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import LocalStorageService from '@/services/LocalStorageService';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';
import nFormatter from '@/utils/nFormatter';
import Error from '@/views/misc/error';

import CreateEvaluationDialog from '../create';

const useStyles = makeStyles()((theme) => ({
  textDisabled: {
    color: theme.palette.text.disabled
  }
}));

const EvaluationsOnBoardingOverlay = () => (
  <OnBoardingOverlay
    icon={<PieChart color="action" sx={{ width: 40, height: 40 }} />}
    title="Start list of Evaluations"
    text="Create an evaluation to start analizing social media posts"
  />
);

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  search: ''
};

const EvaluationsList = () => {
  const theme = useTheme();
  const { classes } = useStyles();
  const alert = useAlert();
  const navigate = useWorkspaceNavigate();
  const workspaceId = Number(useParams().workspaceId);

  const user = useAppSelector((state) => state.user.user);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteIsDialogOpen] = useState(false);
  const [selectedEvaluations, setSelectedEvaluations] = useState([]);
  const [selectedView, setSelectedView] = useState(
    LocalStorageService.getItem('evaluations-list-view') || 'table'
  );
  const [cardViewPage, setCardViewPage] = useState(1);
  const endOfListRef = useRef(null); // Ref for the end of the list

  const evaluationCopy = useRef(undefined);

  const urlParams = useUrlParams(getRoute.evaluations.LIST(), defaultParams);

  const {
    data: evaluations = [],
    isLoading,
    refetch,
    isError
  } = useFetchEvaluations({});

  const filteredEvaluations = useMemo(
    () =>
      evaluations.filter((evaluation) =>
        evaluation.title
          .toLowerCase()
          .includes(urlParams.params.search?.toLowerCase())
      ),
    [evaluations, urlParams.params.search]
  );

  const { mutate: deleteEvaluation, isLoading: isDeleting } =
    useDeleteEvaluation(
      () => {
        const firstSelectedEvaluationName = filteredEvaluations.find(
          (evaluation) => evaluation.id === selectedEvaluations[0]
        ).title;

        const alertMessage =
          selectedEvaluations.length === 1
            ? `"${firstSelectedEvaluationName}" has been successfully deleted`
            : `${selectedEvaluations.length} evaluations have been successfully deleted`;

        alert.info(alertMessage);
        handleCloseDeleteDialog(true);
        refetch();
      },
      () => alert.error(`Some error occurred while deleting the evaluations`)
    );

  const handleChangeSearch = ({ target: { value } }) => {
    if (value === '') {
      urlParams.updateParams({ search: undefined, page: DEFAULT_PAGE });
    } else {
      urlParams.updateParams({ search: value, page: DEFAULT_PAGE });
    }
  };

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    evaluationCopy.current = undefined;
  };

  const handleCloseDeleteDialog = (clearSelection = false) => {
    setDeleteIsDialogOpen(false);
    if (clearSelection) setSelectedEvaluations([]);
  };

  const handleDeleteEvaluations = async (e) => {
    e.preventDefault();
    deleteEvaluation({ ids: selectedEvaluations });
  };

  const handleCopyEvaluation = (id) => {
    getEvaluation(id)
      .then(({ data }) => {
        evaluationCopy.current = data;
        setIsCreateDialogOpen(true);
      })
      .catch(() =>
        alert.error('Something went wrong while copying the evaluation')
      );
  };

  const handleChangeView = (_, newView) => {
    setSelectedView(newView);
    setSelectedEvaluations([]);
    LocalStorageService.setItem('evaluations-list-view', newView);
  };

  const columns = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Title',
        minWidth: 350,
        flex: 1,
        renderCell: (data) => {
          const { title } = data.row;

          return (
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {title}
            </Typography>
          );
        }
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        minWidth: 150,
        flex: 1,
        renderCell: (data) => {
          const { status, createdAt } = data.row;

          switch (status) {
            case 'error':
            case null:
              return (
                <Typography className={classes.textDisabled}>Error</Typography>
              );

            default:
              return <Typography>{getPrettyDate(createdAt)}</Typography>;
          }
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Last Edited',
        minWidth: 150,
        flex: 1,
        renderCell: (data) => {
          const { status, updatedAt } = data.row;

          switch (status) {
            case 'error':
            case null:
              return (
                <Typography className={classes.textDisabled}>-</Typography>
              );

            default:
              return <Typography>{getPrettyDate(updatedAt)}</Typography>;
          }
        }
      },
      {
        field: 'authorName',
        headerName: 'Creator',
        minWidth: 150,
        flex: 1,
        renderCell: (data) => {
          const { status, authorName } = data.row;

          switch (status) {
            case 'error':
            case null:
              return (
                <Typography className={classes.textDisabled}>-</Typography>
              );

            default:
              return <Typography>{authorName}</Typography>;
          }
        }
      },
      {
        field: 'totalItems',
        headerName: 'Items',
        minWidth: 100,
        renderCell: (data) => {
          const { status, totalItems } = data.row;

          switch (status) {
            case 'error':
            case null:
              return (
                <Typography className={classes.textDisabled}>
                  <ErrorIcon color="error" />
                </Typography>
              );

            default:
              return <Typography>{nFormatter(totalItems)}</Typography>;
          }
        }
      },
      {
        field: 'actions',
        type: 'actions',
        width: 50,
        getActions: (data) => [
          <GridActionsCellItem
            icon={<FileCopy />}
            key={`copy-${data.id}`}
            label="Make a copy"
            showInMenu
            onClick={() => handleCopyEvaluation(data.id)}
          />
        ]
      }
    ],
    [filteredEvaluations]
  );

  const isDeleteButtonDisabled = selectedEvaluations?.length <= 0;

  const onChangePageSize = useCallback(
    (newSize) => {
      urlParams.updateParams({ size: newSize });
    },
    [urlParams]
  );

  const onChangePage = useCallback(
    (newPage) => {
      urlParams.updateParams({ page: newPage });
    },
    [urlParams]
  );

  const onSortModelChange = useCallback(
    (params) => {
      const { sort: newOrder, field: newSort } = params[0] || {};
      urlParams.updateParams({ sort: newSort, order: newOrder });
    },
    [urlParams]
  );

  const { sort, order, page, size } = urlParams.params;
  const gridItems = Array.from({ length: 18 }, (_, index) => index + 1);

  const numberOfVisibleCards =
    cardViewPage * 9 - (evaluations.length === 0 ? 1 : 0);

  // UseEffect to add event listener for scroll
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !isLoading
      ) {
        setCardViewPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);

  // Ref callback for the end of the list
  const handleEndOfListRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (endOfListRef.current) return;

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCardViewPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.observe(node);

      endOfListRef.current = node;
    },
    [isLoading]
  );

  let emptyStateEvaluations = EvaluationsOnBoardingOverlay;

  if (urlParams.params.search !== '') {
    emptyStateEvaluations = () => <NoRowsOverlay />;
  }

  if (isError) return <Error />;

  return (
    <>
      <Page
        title="Evaluations"
        header={
          <Header
            actions={
              <>
                <ToggleButtonGroup
                  value={selectedView}
                  exclusive
                  onChange={handleChangeView}
                  aria-label="view"
                  size="small"
                >
                  <ToggleButton
                    value="card"
                    aria-label="card view"
                    style={{
                      color:
                        selectedView === 'card' // FIXME: remove this when action theme colors are fixed (default theme)
                          ? theme.palette.text.primary
                          : theme.palette.text.disabled
                    }}
                    disabled={selectedView === 'card'}
                  >
                    <CalendarViewMonthIcon />
                  </ToggleButton>
                  <ToggleButton
                    value="table"
                    aria-label="table view"
                    style={{
                      color:
                        selectedView === 'table' // FIXME: remove this when action theme colors are fixed (default theme)
                          ? theme.palette.text.primary
                          : theme.palette.text.disabled
                    }}
                    disabled={selectedView === 'table'}
                  >
                    <ViewListOutlinedIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  onClick={() => setIsCreateDialogOpen(true)}
                  text="create evaluation"
                />
                {isCreateDialogOpen && (
                  <CreateEvaluationDialog
                    onClose={handleCloseCreateDialog}
                    evaluationCopy={evaluationCopy.current}
                  />
                )}
              </>
            }
            title="Evaluations"
          />
        }
        toolbar={
          <Toolbar
            isDeleteEnabled={selectedEvaluations.length > 0}
            onClickDelete={() => setDeleteIsDialogOpen(true)}
            onChangeSearch={handleChangeSearch}
            showDeleteButton={!isDeleteButtonDisabled}
            showSearchInput
            searchValue={urlParams.params.search}
            totalItems={evaluations.length || 0}
          />
        }
      >
        {selectedView === 'card' && isLoading && (
          <Grid container spacing={2}>
            {gridItems.map((number) => (
              <Grid item xs={12} sm={6} md={4} key={number}>
                <Skeleton variant="rectangular" height={176} />
              </Grid>
            ))}
          </Grid>
        )}

        {selectedView === 'card' && !isLoading && evaluations.length !== 0 && (
          <>
            <Grid container spacing={2}>
              {filteredEvaluations
                .slice(0, numberOfVisibleCards)
                .map((evaluation) => (
                  <Grid item xs={12} sm={6} md={4} key={evaluation.id}>
                    <CardActionArea
                      LinkComponent={RouterLink}
                      to={`/workspace/${workspaceId}${getRoute.evaluations.DETAIL(
                        evaluation.id
                      )}`}
                    >
                      <Card
                        title={
                          <Typography variant="h6">
                            {evaluation.title}
                          </Typography>
                        }
                        elevation={3}
                        titleEllipsis
                        content={
                          <Stack gap={0.5}>
                            <Typography variant="body2" color="textSecondary">
                              {evaluation.totalItems} items -{' '}
                              {getPrettyDate(evaluation.updatedAt)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Created by {evaluation.authorName}
                            </Typography>
                          </Stack>
                        }
                        actions={
                          <>
                            {evaluation.authorId === user.id && (
                              <Button
                                color="error"
                                variant="text"
                                text="delete"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDeleteIsDialogOpen(true);
                                  setSelectedEvaluations([evaluation.id]);
                                }}
                              />
                            )}
                            <Button
                              variant="text"
                              text="copy"
                              onClick={(e) => {
                                e.preventDefault();
                                handleCopyEvaluation(evaluation.id);
                              }}
                            />
                          </>
                        }
                      />
                    </CardActionArea>
                  </Grid>
                ))}
            </Grid>
            <div ref={handleEndOfListRef} />
          </>
        )}

        {selectedView === 'card' && !isLoading && evaluations.length === 0 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CardActionArea>
                  <Stack
                    sx={{
                      boxShadow:
                        '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                      background: theme.palette.primary.main,
                      height: '176px'
                    }}
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => setIsCreateDialogOpen(true)}
                  >
                    <Stack alignItems="center" justifyContent="center" gap={1}>
                      <Add sx={{ color: theme.palette.primary.contrastText }} />
                      <Typography
                        sx={{ color: theme.palette.primary.contrastText }}
                        color="primary"
                        variant="subtitle2"
                      >
                        CREATE EVALUATION
                      </Typography>
                    </Stack>
                  </Stack>
                </CardActionArea>
              </Grid>
              {gridItems.slice(0, numberOfVisibleCards).map((id) => (
                <Grid item xs={12} sm={6} md={4} key={id}>
                  <Box
                    sx={{
                      border: '1px dashed rgba(0, 0, 0, 0.18);',
                      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.15)',
                      filter:
                        'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.13)) drop-shadow(0px 1px 9px rgba(0, 0, 0, 0.12))'
                    }}
                    width={373}
                    height={176}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {selectedView === 'table' && (
          <DataTable
            checkboxSelection={true}
            columns={columns}
            onRowSelectionModelChange={setSelectedEvaluations}
            rowHeight={72}
            rows={filteredEvaluations}
            rowSelectionModel={selectedEvaluations}
            loading={isLoading}
            onRowClick={(row) => navigate(getRoute.evaluations.DETAIL(row.id))}
            isRowSelectable={(data) => data.row.authorId === user.id}
            onChangePageSize={onChangePageSize}
            onChangePage={onChangePage}
            onSortModelChange={onSortModelChange}
            sort={sort}
            order={order}
            page={page}
            size={size}
            noRowsOverlay={emptyStateEvaluations}
            disableColumnFilter
          />
        )}
      </Page>
      <Dialog
        title="Delete Evaluation"
        dialogContent={
          selectedEvaluations.length === 1 ? (
            <>
              <Typography>
                Are you sure you want to delete &ldquo;
                {
                  filteredEvaluations.find(
                    (item) => item.id === selectedEvaluations[0]
                  )?.title
                }
                &ldquo;?
              </Typography>
              <Typography>
                You will not be able to recover this evaluation.
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Are you sure you want to delete {selectedEvaluations.length}{' '}
                evaluations?
              </Typography>
              <Typography>You will not be able to recover these.</Typography>
            </>
          )
        }
        open={isDeleteDialogOpen}
        onClose={() => handleCloseDeleteDialog(false)}
        actions={
          <>
            <Button
              variant="text"
              text="cancel"
              onClick={() => handleCloseDeleteDialog(false)}
              disabled={isDeleting}
            />
            <Button
              color="error"
              text="delete"
              onClick={handleDeleteEvaluations}
              loading={isDeleting}
            />
          </>
        }
      />
    </>
  );
};

export default EvaluationsList;
