import { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Breakpoint, Container, Paper, Stack } from '@mui/material';

interface Props {
  children: JSX.Element;
  title?: string;
  className?: string;
  header?: JSX.Element;
  toolbar?: JSX.Element | undefined;
  maxWidth?: Breakpoint | false;
  backgroundSvgUrl?: string;
}

const Page = forwardRef(
  (
    {
      children,
      title = '',
      className,
      header,
      toolbar,
      maxWidth = false,
      backgroundSvgUrl = `${process.env.REACT_APP_S3_ASSETS}/misc/background.svg`,
      ...rest
    }: Props,
    ref
  ) => (
    <Box ref={ref} className={className} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {header && (
        <Paper
          elevation={0}
          sx={{
            pt: '20px',
            px: '24px',
            pb: toolbar === undefined ? '20px' : 0,
            mb: 2.5,
            position: 'relative',
            backgroundImage: backgroundSvgUrl
              ? `url(${backgroundSvgUrl})`
              : 'none',
            backgroundPosition: 'right center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            borderBottom: '1px solid var(--Border-Medium, rgba(0, 0, 0, 0.15))'
          }}
        >
          <Container maxWidth={maxWidth} sx={{ px: 0 }}>
            {header}
            {toolbar}
          </Container>
        </Paper>
      )}
      <Stack px="24px">
        <Container maxWidth={maxWidth}>{children}</Container>
      </Stack>
    </Box>
  )
);

Page.displayName = 'Page';

export default Page;
