import { Paper, Tooltip } from '@mui/material';

import { Posts } from '@/models/posts';

interface PostIdentificationMarkersProps {
  items?: Array<Posts.MinorItem | Posts.OcrAdvImageItem>;
  dimensions: {
    width: number;
    height: number;
  };
}

/**
 * Computes the offsets and rendered dimensions of an image centered within its container.
 *
 * When an image is displayed inside a container (e.g., a `div`) and the container's dimensions
 * differ from the aspect ratio of the image, the image is resized to fit while maintaining its
 * aspect ratio. This often results in empty space (gaps) either on the sides (horizontal) or
 * on the top and bottom (vertical), depending on the container and image dimensions.
 *
 * These offsets and rendered dimensions are essential for accurate placement of elements like
 * markers or bounding boxes on the image, ensuring alignment with the visible content of the image.
 *
 * Returns:
 *   An object containing:
 *     - `leftGap`: The horizontal gap (in pixels) on the left side of the centered image.
 *     - `renderedWidth`: The width (in pixels) of the rendered image inside the container.
 *     - `renderedHeight`: The height (in pixels) of the rendered image inside the container.
 *     - `topGap`: The vertical gap (in pixels) at the top of the container (currently unused, defaults to 0).
 *
 * Notes:
 *   - This function uses the `naturalWidth` and `naturalHeight` properties of the image element
 *     to ensure calculations are based on the intrinsic dimensions of the image.
 *   - If the container or image is not found, the function returns default values of `0` for all properties.
 */
const calculateImageOffset = () => {
  const container = document.getElementById('image-container');
  const image = container?.querySelector('img');

  if (container && image) {
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;

    // Use the intrinsic dimensions of the image
    const { naturalWidth, naturalHeight } = image;

    // Calculate the aspect ratio of the image
    const imageAspectRatio = naturalWidth / naturalHeight;

    // Calculate the rendered dimensions of the image within the container
    const renderedHeight = containerHeight;
    const renderedWidth = containerHeight * imageAspectRatio;

    // Calculate the horizontal gap (left gap) when the image is centered
    const leftGap = (containerWidth - renderedWidth) / 2;

    return { leftGap, renderedWidth, renderedHeight };
  }

  // Return default values if the container or image is not found
  return { leftGap: 0, topGap: 0, renderedWidth: 0, renderedHeight: 0 };
};

const PostIdentificationMarkers = ({
  items = []
}: PostIdentificationMarkersProps) => {
  const color = 'yellow';

  const overlayStyle = (item: Posts.MinorItem | Posts.OcrAdvImageItem) => {
    const { leftGap, renderedWidth, renderedHeight } = calculateImageOffset();

    return {
      width: item.coordinates.width * renderedWidth,
      height: item.coordinates.height * renderedHeight,
      left: item.coordinates.left * renderedWidth + leftGap,
      top: item.coordinates.top * renderedHeight,
      transform: `rotate(${item.coordinates.angle || 0}deg)`, // Apply rotation
      transformOrigin: 'top left' // Rotate around the top-left corner
    };
  };

  return (
    <>
      {items.map((item, index) => (
        <Tooltip
          open={true}
          arrow
          title={
            'ageRange' in item && `${item.ageRange.low}-${item.ageRange.high}`
          }
          key={index}
          placement="top"
          disableHoverListener
        >
          <Paper
            id={`identification-marker-${index}`}
            elevation={3}
            sx={{
              ...overlayStyle(item),
              position: 'absolute',
              borderRadius: 0,
              border: `1px solid ${color}`,
              backgroundColor: 'transparent'
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: color,
                opacity: 0.1
              }}
            />
          </Paper>
        </Tooltip>
      ))}
    </>
  );
};

export default PostIdentificationMarkers;
