import { Posts } from '@/models/posts';

import { highlightWords, stringHasKeyword } from '../text';
import { replaceNewLinesWithBreaks, replaceUrlsWithLinks } from '../text/text';

const isOcrAdvVideoItem = (
  item: Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem
): item is Posts.OcrAdvVideoItem => 'textFound' in item;

const filterOcrAdvItems = (
  items: (Posts.OcrAdvVideoItem | Posts.OcrAdvImageItem)[],
  keywords: string[]
) =>
  items.filter((line) => {
    if (isOcrAdvVideoItem(line)) {
      return line.textFound.some((text) =>
        stringHasKeyword(text.content, keywords)
      );
    }
    return stringHasKeyword(line.content, keywords);
  });

export const processMediaWithKeywords = (
  media: Posts.Media[] | undefined,
  selectedKeywords: string[],
  keywordList: string[]
) => {
  if (!media) return [];

  if (selectedKeywords.length === 0) return media;

  if (keywordList.length > 0) {
    const filteredMedia = media.map((mediaItem) => ({
      ...mediaItem,
      visualRecognition: mediaItem.visualRecognition?.map((recognition) => {
        if (recognition.type === 'ocr-adv') {
          const items = recognition.items as
            | Posts.OcrAdvVideoItem[]
            | Posts.OcrAdvImageItem[];
          return {
            ...recognition,
            items:
              selectedKeywords.length > 0
                ? filterOcrAdvItems(items, selectedKeywords)
                : items
          };
        }
        return recognition;
      })
    }));

    const hasFilteredItems = filteredMedia.some(
      (mediaItem) =>
        mediaItem.visualRecognition &&
        mediaItem.visualRecognition.some(
          (recognition) =>
            recognition.type === 'ocr-adv' && recognition.items.length > 0
        )
    );

    if (!hasFilteredItems) return media;

    return filteredMedia.map((mediaItem) => ({
      ...mediaItem,
      visualRecognition: mediaItem.visualRecognition?.map((recognition) => {
        if (recognition.type === 'ocr-adv') {
          const items = recognition.items as
            | Posts.OcrAdvVideoItem[]
            | Posts.OcrAdvImageItem[];
          return {
            ...recognition,
            items: items.map((item) =>
              'textFound' in item
                ? {
                    ...item,
                    textFound: item.textFound.map((text) => ({
                      ...text,
                      content: highlightWords(text.content, selectedKeywords)
                    }))
                  }
                : {
                    ...item,
                    content: highlightWords(item.content, selectedKeywords)
                  }
            )
          };
        }
        return recognition;
      })
    }));
  }

  return media;
};

export const processOldOcrText = (
  media: Posts.Media | undefined,
  selectedKeywords: string[]
): string | undefined => {
  const text = media?.visualRecognition?.find(
    (item) => item.type === 'ocr'
  )?.text;

  if (!text) return undefined;

  let processedText = text;

  if (selectedKeywords.length > 0) {
    processedText = highlightWords(processedText, selectedKeywords);
  }

  processedText = replaceUrlsWithLinks(processedText);
  processedText = replaceNewLinesWithBreaks(processedText);

  return processedText;
};
