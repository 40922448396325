import { useMemo } from 'react';

import {
  Divider,
  LinearProgress,
  Link,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

import PropTypes from 'prop-types';

import Card from '@/components/card/Card';
import InstagramIcon from '@/components/icons/InstagramIcon';
import TikTokIcon from '@/components/icons/TikTokIcon';
import YoutubeIcon from '@/components/icons/YoutubeIcon';
import PLATFORM_LINKS from '@/constants/platformLinks';
import nFormatter from '@/utils/nFormatter/nFormatter';

const tilesMap = {
  instagram: (
    <>
      <InstagramIcon fontSize="medium" />
      <Typography variant="subtitle1">Instagram Accounts</Typography>
    </>
  ),
  tiktok: (
    <>
      <TikTokIcon fontSize="medium" />
      <Typography variant="subtitle1">Tiktok Accounts</Typography>
    </>
  ),
  youtube: (
    <>
      <YoutubeIcon fontSize="medium" />
      <Typography variant="subtitle1">Youtube Accounts</Typography>
    </>
  )
};

const getPlatformLink = (platform, username) => {
  const newUsername = username.startsWith('@') ? username.slice(1) : username;
  return PLATFORM_LINKS[platform] + newUsername;
};

const Account = ({ platform, account, showBorderBottom }) => {
  const { username, followers, totalItems, itemsRetrieved, status } = account;
  const theme = useTheme();

  const validStatus = username;

  return (
    <>
      <Stack
        gap={2}
        sx={{ cursor: validStatus ? 'pointer' : 'default' }}
        onClick={() =>
          validStatus
            ? window.open(getPlatformLink(platform, username), '_blank')
            : undefined
        }
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link underline={validStatus ? 'hover' : 'none'}>
            <Typography variant="body2" color="secondary">
              {username?.startsWith('@') || username?.startsWith('UC')
                ? username
                : `@${username}`}
            </Typography>
          </Link>
          <Stack
            direction="row"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body2" flexDirection="row">
              Status:
            </Typography>
            <Typography variant="body2" fontWeight={500}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Typography>
          </Stack>
        </Stack>
        {status === 'active' && (
          <Stack
            direction="row"
            padding="12px 16px"
            gap={2}
            justifyContent="center"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
          >
            <Stack gap={1} alignItems="flex-start" flexGrow={1}>
              <Typography variant="caption" noWrap>
                Followers
              </Typography>
              <Typography variant="subtitle1">
                {nFormatter(followers)}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                display: { xs: 'none', sm: 'flex' }
              }}
            />
            <Stack gap={1} alignItems="flex-start" flexGrow={1}>
              <Typography variant="caption" noWrap>
                Total Posts
              </Typography>
              <Typography variant="subtitle1">
                {nFormatter(totalItems)}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                display: { xs: 'none', sm: 'flex' }
              }}
            />
            <Stack gap={1} alignItems="flex-start" flexGrow={1}>
              <Typography variant="caption" noWrap>
                Posts Retrieved
              </Typography>
              <Typography variant="subtitle1">
                {nFormatter(itemsRetrieved)}
              </Typography>
            </Stack>
          </Stack>
        )}
        {status === 'pending' && <LinearProgress color="secondary" />}
      </Stack>
      {showBorderBottom && (
        <Divider flexItem color={theme.palette.secondary.main} />
      )}
    </>
  );
};

Account.propTypes = {
  platform: PropTypes.oneOf(['instagram', 'tiktok', 'youtube']),
  account: PropTypes.shape({
    followers: PropTypes.any,
    itemsRetrieved: PropTypes.any,
    totalItems: PropTypes.any,
    username: PropTypes.any,
    status: PropTypes.string
  }),
  showBorderBottom: PropTypes.any
};

const Accounts = ({ accounts = {} }) => {
  const platforms = useMemo(
    () =>
      Object.keys(accounts).filter((platform) => accounts[platform].length > 0),
    [accounts]
  );

  return (
    <Stack direction="row" gap={2}>
      {platforms.map((platform) => (
        <Card
          key={platform}
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              {tilesMap[platform]}
            </Stack>
          }
          sx={{
            marginBottom: '16px',
            height: 'fit-content',
            flexBasis: '100%'
          }}
          content={
            <Stack gap={2}>
              {accounts[platform].map((account, index) => (
                <Account
                  key={`${platform}-${index}`}
                  platform={platform}
                  account={account}
                  showBorderBottom={index !== accounts[platform].length - 1}
                />
              ))}
            </Stack>
          }
        />
      ))}
    </Stack>
  );
};

Accounts.propTypes = {
  accounts: PropTypes.shape({
    instagram: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string,
        followers: PropTypes.number,
        totalItems: PropTypes.number,
        latestUpdate: PropTypes.any,
        itemsRetrieved: PropTypes.number,
        status: PropTypes.string
      })
    ),
    youtube: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string,
        followers: PropTypes.number,
        totalItems: PropTypes.number,
        latestUpdate: PropTypes.any,
        itemsRetrieved: PropTypes.number,
        status: PropTypes.string
      })
    ),
    tiktok: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string,
        followers: PropTypes.number,
        totalItems: PropTypes.number,
        latestUpdate: PropTypes.any,
        itemsRetrieved: PropTypes.number,
        status: PropTypes.string
      })
    )
  })
};

export default Accounts;
