import { Grid, Stack, styled } from '@mui/material';

import shadows from '@/themes/theme/shadows';

import PostCompliance from './_partials/PostCompliance';
import PostCreator from './_partials/PostCreator';
import PostKeywordsDetails from './_partials/PostKeywordsDetails';
import PostMedia from './_partials/PostMedia';
import PostTranscript from './_partials/PostTranscript';
import PostVisuals from './_partials/PostVisuals';

const ScrollableStack = styled(Stack)(({ theme }) => ({
  display: 'block',
  maxHeight: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '16px',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.paper,
  boxShadow: shadows.cardDefault,
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',

  // More prominent scrollbar
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,

  // Enhanced spacing and borders between components
  '& > *': {
    border: `1px solid ${theme.palette.divider}`,
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'
  }
}));

const PostLayout = () => (
  <Grid
    container
    spacing={2}
    sx={{
      height: 'calc(100vh - 108px)' // Full viewport height minus any header offset
    }}
  >
    {/* Left Grid */}
    <Grid item xs={12} md={8} sx={{ height: '100%' }}>
      <Stack gap={2} sx={{ height: '100%' }}>
        <PostCreator />
        <PostMedia />
      </Stack>
    </Grid>

    {/* Right Grid */}
    <Grid item xs={12} md={4} sx={{ height: '100%' }}>
      <ScrollableStack>
        <PostKeywordsDetails />
        <PostTranscript />
        <PostVisuals />
        <PostCompliance />
      </ScrollableStack>
    </Grid>
  </Grid>
);

export default PostLayout;
