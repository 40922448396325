import { useContext, useMemo, useEffect, useRef } from 'react';

import { Stack, Typography, Link } from '@mui/material';

import parse, { HTMLReactParserOptions, Element } from 'html-react-parser';

import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import { processOldOcrText } from '@/utils/ocr/ocr';

const PostOcrOld = () => {
  const { data, selectedKeywords, activeIndex, hasOcrOldItems } =
    useContext(PostDetailsContext);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const highlightedOldOcr = useMemo(
    () => processOldOcrText(data?.media[activeIndex], selectedKeywords),
    [data, selectedKeywords, activeIndex]
  );

  const textSegments = useMemo(
    () => (highlightedOldOcr ? highlightedOldOcr.split('--') : []),
    [highlightedOldOcr]
  );

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        const { attribs } = domNode;
        if (attribs?.href) {
          return (
            <Link href={attribs.href} target="_blank">
              {attribs.href}
            </Link>
          );
        }
      }
      return domNode;
    }
  };

  useEffect(() => {
    if (containerRef.current && selectedKeywords.length > 0) {
      const highlightedElement = containerRef.current.querySelector('b');
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [highlightedOldOcr, selectedKeywords]);

  if (!data) {
    return <Typography variant="body2">Data not available</Typography>;
  }

  return hasOcrOldItems ? (
    <Stack
      ref={containerRef}
      sx={{
        overflow: 'auto',
        maxHeight: 240
      }}
    >
      {textSegments.map((segment, index) => (
        <Typography key={index} variant="caption" color="textSecondary">
          {parse(segment, options)}
        </Typography>
      ))}
    </Stack>
  ) : (
    <></>
  );
};

export default PostOcrOld;
